import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { Form, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { signupRequest } from '../../Common/requests'

import ArrowSVG from '../../assets/icons/arrow-up.svg'
import GoogleSVG from '../../assets/icons/google.svg'
import FacebookImg from '../../assets/icons/facebook.svg'
import SiteLOGO from '../../assets/images/logofinalnew.png'
import SimpleReactValidator from "simple-react-validator";

import PropTypes from 'prop-types'
import Input, { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const Signup = () => {
    const [signup, setSignup] = useState(false);
    const [validator] = useState(new SimpleReactValidator());
    const [disabled, setDisabled] = useState(false);
    const [, forceUpdate] = useState();

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [country, setCountry] = useState('AU')
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [termsCheck, setTermsCheck] = useState(false);

    const [showPassword, setShowPassword] = useState(false)
    const [showCPassword, setShowCPassword] = useState(false)


    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const CountrySelect = ({ value, onChange, labels, ...rest }) => (
        <select
            {...rest}
            value={country}
            onChange={event => onChange(event.target.value || undefined)}>
            <option value="">
                {labels["ZZ"]}
            </option>
            {getCountries().map((country) => (
                <option key={country} value={country}>
                    +{getCountryCallingCode(country)}
                </option>
            ))}
        </select>
    )

    CountrySelect.propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        labels: PropTypes.objectOf(PropTypes.string).isRequired,
    }

    const handleSignup = async (evt) => {
        if (typeof evt !== "undefined" && evt != null) {
            evt.preventDefault();
        }

        if (!validator.allValid()) {
            validator.showMessages();
            forceUpdate(1);
            setDisabled(false);
        } else if ((email === null || email === "") && (phone === null || phone === "")) {
            toast.error("Phone Number or Email Address is required for Username.");
        } else {
            setDisabled(true);
            let data = { email: email, password: password, name: { firstName: firstName, lastName: lastName }, mobilePhone: phone };
            signupRequest(data);
        }

        if (disabled)
            setDisabled(false);
    }

    return (
        <div className={`auth-page ${signup ? "signup-form" : ""}`}>
            <div className="container">
                <div className="block-content inner-head">
                    <Link className="icon" to="/">
                        <ReactSVG src={process.env.PUBLIC_URL + ArrowSVG} />
                    </Link>
                    {/* <img src={process.env.PUBLIC_URL + SiteLOGO} alt="img" /> */}
                </div>
                {signup ?
                    <div className="block-content content-holder">
                        <form onSubmit={handleSignup} noValidate>
                            <Row>
                                <Col lg={6} sm={12}>
                                    <div className="form-group">
                                        <label>First Name *</label>
                                        <input type="text" className="form-control" placeholder="Your First Name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                                        {validator.message(
                                            "first_name",
                                            firstName,
                                            "required|alpha_space",
                                            { className: "text-danger" }
                                        )}
                                    </div>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <div className="form-group">
                                        <label>Last Name *</label>
                                        <input type="text" className="form-control" placeholder="Your Last Name" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                                        {validator.message(
                                            "last_name",
                                            lastName,
                                            "required|alpha_space",
                                            { className: "text-danger" }
                                        )}
                                    </div>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={true}
                                            countrySelectProps={{ unicodeFlags: true }}
                                            defaultCountry="AU"
                                            value={phone}
                                            onChange={(number) => setPhone(number)}
                                            placeholder="Your Phone Number"
                                        />
                                        {validator.message(
                                            "phone",
                                            phone,
                                            "phone",
                                            { className: "text-danger" }
                                        )}
                                        {/* <input type="tel" className="form-control" placeholder="Your Phone Number" value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
                                        <div className="country-select">
                                            <CountrySelect
                                                labels={en}
                                                value={country}
                                                onChange={setCountry}
                                            />
                                            <Input
                                                className='PhoneInputInput'
                                                international
                                                country={country}
                                                value={phone}                                            
                                                onChange={(number)=>{setPhone(number)}}
                                                placeholder="Your Phone Number"
                                            /> 
                                        </div> */}
                                    </div>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="email" name='email' className="form-control" placeholder="Your Email Address" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        {validator.message(
                                            "email",
                                            email,
                                            "email",
                                            { className: "text-danger" }
                                        )}
                                    </div>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <div className="form-group">
                                        <label>Password *</label>
                                        <input  type={showPassword ? "text" : "password"} className="form-control" placeholder="Create a Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                        {validator.message(
                                            "password",
                                            password,
                                            "required|min:8",
                                            { className: "text-danger" }
                                        )}
                                        <span className="form-icon" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                        </span>
                                    </div>
                                </Col>
                                <Col lg={6} sm={12}>
                                    <div className="form-group">
                                        <label>Confirm Password *</label>
                                        <input  type={showCPassword ? "text" : "password"} className="form-control" placeholder="Re-enter Password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                                        {validator.message(
                                            "confirm_password",
                                            confirmPassword,
                                            `required|min:8|in:${password}`,
                                            {
                                                className: "text-danger",
                                                messages: { in: 'Passwords do not match.' }
                                            }
                                        )}
                                        <span className="form-icon" onClick={() => setShowCPassword(!showCPassword)}>
                                            {showCPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            <div className="flex align-items-center">
                                <Form.Check className="checkbox" type="checkbox" checked={termsCheck} onChange={() => setTermsCheck(!termsCheck)} />
                                <h6 className="m-0">I agree to the <a target="_blank" href="https://partner.retainoo.com/privacy-policy/" className="link">privacy policy.</a></h6>
                            </div>
                            <div className="button-holder mt-3">
                                <button type="submit" className="btn btn-secondary" disabled={(!termsCheck)}>Sign up</button>
                            </div>
                        </form>
                        <div className="text-center mt-4">
                            <h6 className="text-grey">Already have an account?</h6>
                            <Link className="link" to="/login">Login now</Link>
                        </div>
                    </div>
                    :
                    <div className="block-content content-holder">
                        <div className="">
                            <h5>Signup for personalized experience</h5>
                            <h6 className="text-grey">Log in or sign up to book your first treatment today!</h6>
                        </div>
                        <div className="buttons-group">
                            <button className="btn btn-secondary" onClick={() => setSignup(!signup)}>Sign up</button>
                            {/* <button className="btn btn-social">
                                <div className="icon"><ReactSVG className="svg-icon" src={GoogleSVG} /></div>
                                Continue with Google
                            </button>
                            <button className="btn btn-social">
                                <div className="icon"><ReactSVG className="svg-icon fb-icon" src={FacebookImg} /></div>
                                Continue with Facebook
                            </button> */}
                        </div>
                        <div className="text-center">
                            <h6 className="text-grey">Already have an account?</h6>
                            <Link className="link" to="/login">Login now</Link>
                        </div>
                    </div>
                }
                {/* <div className="block-content content-holder">
                    <div className="text-center">
                        <h5>Become a Partner</h5>
                        <h6 className="text-grey">Manage your business with Retainoo by <Link to="#" className="link">signing up as a professional</Link></h6>
                    </div>
                </div> */}
                <h6 className="text-grey text-center"> &copy; 2022 Retainoo.com</h6>
            </div>
        </div>
    );
}

export default Signup;
