import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';

import { loginRequest } from '../../Common/requests'

import ArrowSVG from '../../assets/icons/arrow-up.svg'
import GoogleSVG from '../../assets/icons/google.svg'
import FacebookImg from '../../assets/icons/facebook.svg'
import SiteLOGO from '../../assets/images/logofinalnew.png'
import SimpleReactValidator from "simple-react-validator";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const Login = () => {
    const [login, setLogin] = useState(false);
    const [validator] = useState(new SimpleReactValidator());
    const [disabled, setDisabled] = useState(false);
    const [, forceUpdate] = useState();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const handleLogin = async (evt) => {
        if (typeof evt !== "undefined" && evt != null) {
            evt.preventDefault();
        }

        if (!validator.allValid()) {
            validator.showMessages();
            forceUpdate(1);
            setDisabled(false);
        } else {
            setDisabled(true);
            let data = { email: username, password: password };
            let response = await loginRequest(data);
            if (response !== 200)
                setDisabled(false);
        }
    }

    return (
        <div className="auth-page">
            <div className="container">
                <div className="block-content inner-head">
                    <Link className="icon" to="/">
                        <ReactSVG src={process.env.PUBLIC_URL + ArrowSVG} />
                    </Link>
                    {/* <img src={process.env.PUBLIC_URL + SiteLOGO} alt="img" /> */}
                </div>
                {login ?
                    <div className="block-content content-holder">
                        <form onSubmit={handleLogin} noValidate>
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" className="form-control" placeholder="Username" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                                {validator.message(
                                    "username",
                                    username,
                                    "required",
                                    { className: "text-danger" }
                                )}
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                {validator.message(
                                    "password",
                                    password,
                                    "required",
                                    { className: "text-danger" }
                                )}
                                <span className="form-icon" onClick={()=>setShowPassword(!showPassword)}>
                                   {showPassword ? <AiFillEye/>  : <AiFillEyeInvisible/>}
                                </span>
                            </div>
                            <Link to="/forgot-password" className="link text-right">Forgot password?</Link>
                            <div className="button-holder mt-3">
                                <button type="submit" className="btn btn-secondary" disabled={disabled}>Login</button>
                            </div>
                        </form>
                        <div className="text-center mt-3">
                            <h6 className="text-grey">Don't have a customer account?</h6>
                            <Link className="link" to="/signup">Signup now</Link>
                        </div>
                    </div>
                    :
                    <div className="block-content content-holder">
                        <div className="">
                            <h5>Login</h5>
                            <h6 className="text-grey">Log in or sign up to book your first treatment today!</h6>
                        </div>
                        <div className="buttons-group">
                            <button className="btn btn-secondary" onClick={() => setLogin(!login)}>Log in</button>
                            {/* <button className="btn btn-social">
                                <div className="icon"><ReactSVG className="svg-icon" src={GoogleSVG} /></div>
                                Continue with Google
                            </button>
                            <button className="btn btn-social">
                                <div className="icon"><ReactSVG className="svg-icon fb-icon" src={FacebookImg} /></div>
                                Continue with Facebook
                            </button> */}
                        </div>
                        <div className="text-center">
                            <h6 className="text-grey">Don't have a customer account?</h6>
                            <Link className="link" to="/signup">Signup now</Link>
                        </div>
                    </div>
                }
                {/* <div className="block-content content-holder">
                    <div className="text-center">
                        <h5>Login for businesses</h5>
                        <h6 className="text-grey">To access your business account please go to the<Link to="#" className="link">business login page</Link></h6>
                    </div>
                </div> */}
                <h6 className="text-grey text-center"> &copy; 2022 Retainoo.com</h6>
            </div>
        </div>
    );
}

export default Login;
