import React from 'react';
import {Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import DummyImg from '../assets/images/3-2.png'

const Item = styled.div `
    background: #101928; color: #fff; border-radius: 16px; margin-bottom: 60px; padding-top: 50px;
    .desc {padding: 100px 80px; }

    .row {margin: 0;}
    .row [class^="col-"] {padding: 0;}

    img {height: 100%; border-radius: 8px 0 15px 0;}

    @media screen and (max-width: 991px){
        .desc {padding: 50px 30px}
    }
`;

const AboutSubscription = (props) => {

    return (
        <div className="container">
            <Item className="learn-more">
                <Row>
                    <Col lg={7} md={12}>
                        <div className="desc">
                            <h2 className="mb-3">Are you a hair & beauty service provider? Grow your business on autopilot with the most innovative solution that is FREE to use.</h2>
                            <p className="mb-5 font-size-14">With our FREE innovative solution, get new clients, retain existing clients and save time managing and growing your salon with total automation and control.</p>
                            <a href="https://partner.retainoo.com" className="btn btn-rounded btn-primary" target="_blank">Learn More</a>
                        </div>
                    </Col>
                    <Col lg={5} md={12}>
                        <img src={DummyImg} alt="img" />
                    </Col>
                </Row>
            </Item>
        </div>
    );
}

export default AboutSubscription;
