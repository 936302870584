import React from 'react';
import styled from 'styled-components';
import DummyProfile from '../assets/images/DummySalonBackground.jpg'

import HeartSVG from '../assets/icons/heart.svg'
import { AiFillStar } from "react-icons/ai";
import { getKey } from '../Common/helper';

const Item = styled.div `
    position: relative; margin-bottom: 20px; transition: all .3s ease;
    .image {position: relative; height: 180px; overflow: hidden; border-radius: 4px; transition: all .3s ease; background-repeat: no-repeat; background-position: center; background-size: 100%}
    .image img {width: 100%; height: 180px; object-fit: cover; }

    .heart-icon {cursor: pointer; position: absolute; right: 15px; top: 15px; z-index: 9;}
    .heart-icon svg {width: 20px;}

    .label {position: absolute; left: 15px; top: 15px; z-index: 1;}
    .popular-label { background: #01358d !important; color: #fff;}

    .h-list {
        grid-gap: 15px;
        position: absolute;
        top: 0px;
        right: 15px;
        display: block;
    }
    .h-list span { margin-left: 2px;}
`;

const SearchResultCard = (props) => {
    const {item} = props;
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const salonType = (type) =>{
        if(type == "male")
            return "Male Only";
        else if(type == "female")
            return "Female Only";
        else if(type == "both")
            return "Unisex";
    }
    
    const url = (item) =>{
        return (item.salonProfile && item.salonProfile.includes('default') ? DummyProfile : (item.salonProfile ? item.salonProfile : DummyProfile))
    }

    return (
        <>
            <Item className="search-card">
                <a href={`/salon-detail/${item.id}`} className="stretched-link"></a>
                {/* <a href={item?.subDomain ? "https://"+item?.subDomain+".retainoo.com/#/public/salon-info/"+item?.id+"?token="+(getKey() ? getKey() : "") : item?.website} target="_blank" className="stretched-link"></a> */}
                <div className="image" style={{backgroundImage: `url(${url(item)})`}}>
                    {/* <ReactSVG className="heart-icon" src={HeartSVG} /> */}
                    {item.subscriptionType === "PREMIUM" ? (<div className="heart-icon label popular-label">FEATURED</div>) : ""}
                    {item?.sex ?
                        <div className="label">{salonType(item?.sex)}</div>
                    : ""}
                    {item?.loyaltyPointsEnabled ?
                        <div className="label">Offer Loyalty Points</div>
                    : ""}
                    {item?.anniversaryBonusLoyaltyPointsEnabled || item?.birthdayBonusLoyaltyPointsEnabled || item?.downloadAppBonusLoyaltyPointsEnabled || item?.firstSaleBonusLoyaltyPointsEnabled ?
                        <div className="label">Offer Bonus Loyalty Points</div>
                    : ""}
          
                    {/* <Slider {...settings}>
                        <div className="image"><img src={item.salonProfile && item.salonProfile.includes('default') ? DummyProfile : (item.salonProfile ? item.salonProfile : DummyProfile)} alt="img" /></div>
                    </Slider> */}
                </div>
                <div className="grid mt-2">
                    <h5 className="mb-1 text-truncate">{item?.legalName}</h5>
                    <h6 className="text-grey text-truncate">{item?.addressLine2 ? (item?.addressLine1+item?.addressLine2+', '+item?.suburb+', '+item?.state) : item?.addressLine1+', '+item?.suburb+', '+item?.state}</h6>
                    <div className="h-list mt-2">
                    {
                        item?.reviewAverage > 0 ?    <div className="font-weight-600 rating-start"><AiFillStar /><span>{item?.reviewAverage?.toFixed(1)}</span> <span className="text-grey">({item?.ratingCount})</span></div>
                         : <div className="font-weight-600 rating-start">0 reviews</div>
                    }
                    </div>
                </div>
            </Item>
        </>
    );
}

export default SearchResultCard;
