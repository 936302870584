import { HOME_PAGE } from "./constants";

export function setUser(user){
    localStorage.setItem("user",user);
}

export function getUser(){
    return localStorage.getItem("user");
}

export function setToken(token){
    localStorage.setItem("token",token);
}

export function getToken(){
    return localStorage.getItem("token");
}

export function setKey(key){
    localStorage.setItem("key",key);
}

export function getKey(){
    return localStorage.getItem("key");
}

export function clearStorage(){
    localStorage.clear(); 
}

export function logout(){
    localStorage.clear(); 
    window.location = HOME_PAGE;
}

export function getUserName(){
    const user = JSON.parse(getUser());
    return user?.firstName+" "+user?.lastName;
}
