import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

import MobileMenu from '../Components/MobileView/MobileMenu';
import SearchMoileMenu from '../Components/MobileView/SearchMoileMenu';

// assets
import SiteLOGO from '../assets/images/logofinalnew.png'
import SearchSVG from '../assets/icons/search.svg'
import MenuSVG from '../assets/icons/bars-solid.svg'
import CrossSvg from '../assets/icons/cross.svg'
import ArrowDownV2 from '../assets/icons/arrow-up.svg'
import userImg from '../assets/images/user.png'
import { getToken, getUserName, logout } from '../Common/helper';

const Item = styled.div`
    z-index: 9999; position: relative; transition: all .3s ease; width: 100%; background: #fff; display: flex; align-items:center; justify-content: space-between; padding: 19px 40px; box-shadow: 0px 1px 3px rgba(0, 0, 0, .15);
    .logo img {max-width: 140px;}

    .menu-button {
        position: relative; min-width: 85px; cursor: pointer; transition: all .3s ease; font-size: 17px; display: flex; justify-content: flex-end; align-items: center;
    }

    .menu-button,
    a {font-size: 16px; font-weight: 500; color: var(--link-color); &.active-nav {    border: 1px solid  var(--link-color);color: #fff;background: var(--link-color);padding: 6px 20px;border-radius: 20px;font-weight: 600;}}
    .menu-button.active,
    .menu-button:hover,
    a:hover {color: var(--primary)}

    .menu-button {
        .dropdown-menu {padding: 10px; position: absolute; z-index: 600; overflow-y: auto; top: 100%; right: 0px; max-height: calc((100vh - 72px) - 16px); display: block; background: rgb(255, 255, 255); border-radius: 4px; border: 1px solid rgba(16, 25, 40, 0.1); box-shadow: rgba(16, 25, 40, .10) 0px 4px 6px 0px; transition: all 200ms ease 0s; transform: translateY(0px); opacity: 0; pointer-events: none;}
        .dropdown-menu a {font-size: 14px; color: var(--content-color); display: flex; background: none; border: none; text-decoration: none; padding: 0px 16px; margin: 0px; height: 38px; white-space: nowrap; -webkit-box-align: center; align-items: center; width: 100%; outline: none; transition: all 200ms ease 0s;}
        .dropdown-menu a:hover {color: #676767;}
        .dropdown-menu li:last-child a {border-top: 1px solid #e7e8e9; min-height: 44px; margin-top: 4px;}
        .dropdown-menu li:last-child .svg-icon {margin-right: 0;}
        &.active .dropdown-menu { transform: translateY(20px); opacity: 1; pointer-events: all;}
    }

    .profile-dropdown {
        .btn {background: transparent; padding: 0; outline: none; box-shadow: none !important;}
        .dropdown-toggle::after {border-top-color: #000;}
        .show.dropdown .dropdown-toggle::after {transform: rotate(180deg)}

        .dropdown-menu {min-width: 220px; padding: 0; border-color: #e7e7e7; box-shadow: 0px 3px 10px rgba(0,0,0,.10)}
        .dropdown-item {padding: 12px 15px; background: #fff !important;}
        .dropdown-item:not(:last-child) {border-bottom: 1px solid #e7e7e7}
        h6 {margin: 0; font-size: 20px; pointer-events: none; font-weight: 600; padding: 25px 15px !important;}
    }

    @media screen and (max-width: 1440px){
        padding: 15px 20px;
    }
    @media screen and (max-width: 991px){
        .menu-button,
        a {font-size: 13px;}

        .menu-button {min-width: 75px;}

        .h-list {grid-gap: 15px;}
    }
`;

const Header = () => {
    const [menu, SetMenu] = useState(false);
    const [mobilemenu, Setmobilemenu] = useState(false);
    const [Searchmenu, SetSearchmenu] = useState(false);

    const ToggleAction = () => SetMenu(!menu)
    let location = useLocation();

    const showSearch = () => {
        document.querySelector('.search-bar').classList.toggle("open");
    }

    return (
        <Item className="site-header">
            <div className="h-list">
                <Link to="/" className="logo">
                    <img src={process.env.PUBLIC_URL + SiteLOGO} alt="img" />
                </Link>
            </div>

            {getToken() ?
                <>
                    <div class="profile-dropdown">
                        {/* { location.pathname.match("/search") ?
                            <div className="show-on-mobile">
                                <div className="icon-rounded" onClick={showSearch}>
                                    <ReactSVG className="svg-icon" src={SearchSVG} />
                                </div>
                            </div> : ""
                        } */}
                        <div className='d-flex align-items-center'>
                            <Link to="/deals" className='mx-4 d-none d-sm-block active-nav'>Deals</Link>
                            <Dropdown>
                                <Dropdown.Toggle className="btn">
                                    <div className="icon-rounded">
                                        <img src={userImg} alt="user" />
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {getUserName() ? <h6 className="dropdown-item">{getUserName()}</h6> : <></>}
                                    <Dropdown.Item href="/profile"> My Profile </Dropdown.Item>
                                    <Dropdown.Item href="/deals"> Deals </Dropdown.Item>
                                    <Dropdown.Item href="https://apps.apple.com/au/app/retainoo-salon-app/id1448167947" target="_blank">Download IOS app</Dropdown.Item>
                                    <Dropdown.Item href="https://play.google.com/store/apps/details?id=com.retainoo&hl=en&gl=US" target="_blank">Download Android app</Dropdown.Item>
                                    <Dropdown.Item href='https://partner.retainoo.com/' className="fr-auto" target="_blank">List your business</Dropdown.Item>
                                    <Dropdown.Item onClick={logout}> Log Out </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>


                        </div>
                    </div>
                </>
                :
                <div className="flex">
                    <div className="h-list hide-on-mobile">
                        <Link className="active-nav" to="/deals">Deals</Link>
                        <Link to="/login">Log in</Link>
                        <Link to="/signup" >Sign up</Link>
                        <div className={menu ? "menu-button active" : "menu-button"} onClick={ToggleAction}>
                            {/* <span>Menu</span> */}
                            <ReactSVG className="svg-icon" src={MenuSVG} />
                            <ul className="dropdown-menu">
                                <li>
                                    <a href="https://apps.apple.com/au/app/retainoo-salon-app/id1448167947" target="_blank">Download IOS app</a>
                                </li>
                                <li>
                                    <a href="https://play.google.com/store/apps/details?id=com.retainoo&hl=en&gl=US" target="_blank">Download Android app</a>
                                </li>
                                <li>
                                    <a href='https://partner.retainoo.com/' className="fr-auto" target="_blank">List your business</a>
                                </li>
                            </ul>
                        </div>
                        {
                            menu ? <div className='overlay active' onClick={ToggleAction}></div> : <div className='overlay'></div>
                        }
                    </div>

                    <div className="show-on-mobile">
                        {/* <div className="icon-rounded" onClick={() => SetSearchmenu(!Searchmenu)}>
                            <ReactSVG className="svg-icon" src={SearchSVG} />
                        </div> */}
                        <div className="icon-rounded" onClick={() => Setmobilemenu(!mobilemenu)}>
                            <ReactSVG className="svg-icon" src={MenuSVG} />
                        </div>
                        {mobilemenu ? <MobileMenu handleClick={() => Setmobilemenu(!mobilemenu)} /> : ''}
                        {/* {Searchmenu ? <SearchMoileMenu handleClick={() => SetSearchmenu(!Searchmenu)} /> : ''} */}
                    </div>

                </div>
            }
        </Item>
    );
}

export default Header;
