import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SiteLOGO from '../../assets/images/logofinalnew.png'
import CorssSVG from '../../assets/icons/cross.svg'
import ArrowDown from '../../assets/icons/angle-down.svg'
import ArrowDownV2 from '../../assets/icons/arrow-up.svg'

const Item = styled.div`
    position: fixed; left: 0; top: 0; transform: translateY(100%); transition: all .2s ease; width: 100%; height: 100vh; background: #fff; padding: 15px 20px; z-index: 89;

    .item {padding: 18px 10px 18px 0; font-size: 15px; font-weight: 600; display: flex; align-items: center; justify-content: space-between;}
    .item .svg-icon {margin-right: 0; width: 14px; }
    .item:not(:last-child) .svg-icon {transform: rotate(-90deg)}
    .item:not(:last-child) {border-bottom: 1px solid #CCC;}
    .item:last-child .svg-icon {width: 25px;}

    &.active {transform: translateY(0);}
`;

const MobileMenu = (props) => {

    return (
        <Item className={`mobile-menu active`}>
            <div className="fr-auto mb-5">
                <Link to="/" className="logo">
                    <img src={process.env.PUBLIC_URL + SiteLOGO} alt="img" />
                </Link>
                <div className="icon-rounded" onClick={props.handleClick}>
                    <ReactSVG className="svg-icon" src={CorssSVG} />
                </div>
            </div>
            <div className="grid">
                <Link onClick={props.handleClick} to="/deals" className="item">
                    Deals
                    <ReactSVG className="svg-icon" src={ArrowDown} />
                </Link>
                <Link onClick={props.handleClick} to="/login" className="item">
                    Log in
                    <ReactSVG className="svg-icon" src={ArrowDown} />
                </Link>
                <Link onClick={props.handleClick} to="/signup" className="item">
                    Sign up
                    <ReactSVG className="svg-icon" src={ArrowDown} />
                </Link>
                <a onClick={props.handleClick} href="https://apps.apple.com/au/app/retainoo-salon-app/id1448167947" className="item" target="_blank">
                    Download IOS app
                    <ReactSVG className="svg-icon" src={ArrowDown} />
                </a>
                <a onClick={props.handleClick} href="https://play.google.com/store/apps/details?id=com.retainoo&hl=en&gl=US" className="item" target="_blank">
                    Download Android app
                    <ReactSVG className="svg-icon" src={ArrowDown} />
                </a>
                <a onClick={props.handleClick} href="https://partner.retainoo.com/" className="item" target="_blank">
                    List your business
                    <ReactSVG className="svg-icon" src={ArrowDown} />
                </a>
                <Link onClick={props.handleClick} to="#" className="item" />
            </div>
        </Item>
    );
}

export default MobileMenu;
