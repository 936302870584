import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import DummyProfile from '../assets/images/DummySalonBackground.jpg'

// assets
import StarIcon from '../assets/icons/star.svg';
import { getFeatureSalons } from '../Common/requests';
import { getKey } from '../Common/helper';
import { AiFillStar } from 'react-icons/ai';

const Item = styled.div `
    position: relative; margin-bottom: 20px;
    .image {position: relative; height: 180px; overflow: hidden; border-radius: 4px; transition: all .3s ease; background-repeat: no-repeat; background-position: center; background-size: 100%}
    .image img {width: 100%; height: 100%; object-fit: cover;}

    .label {position: absolute; left: 15px; top: 15px; z-index: 5;}
    .popular-label {position: absolute; right: 15px; top: 15px; z-index: 1; left:auto;}

    .rating-start {display: flex; align-items: center;}
    .rating-start svg {width: 16px; margin-right: 8px;}

    .h-list {grid-gap: 15px;}
    
`;

const RetainooFeatured = () => {
    const [featureSalons, setFeatureSalons] = useState([]);
    const [searching, setSearching]= useState(true);

    async function fetchFeatureSalons() {
        const response = await getFeatureSalons();
        setFeatureSalons(response.featuredSaloons);        
        setSearching(false);
    }

    useEffect(()=>{
        setSearching(true);
        fetchFeatureSalons();
    },[])
    
    const salonType = (type) =>{
        if(type == "male")
            return "Male Only";
        else if(type == "female")
            return "Female Only";
        else
            return "Unisex";
    }

    const url = (item) =>{
        return (item.salonProfile && item.salonProfile.includes('default') ? DummyProfile : (item.salonProfile ? item.salonProfile : DummyProfile))
    }

    return (
        (featureSalons?.length > 0 ?
            <section className="featured-section">
                <div className="container">
                    <h3 className="section-title">Featured Venues</h3>
                    <Row>
                        {
                            featureSalons?.map(item => (
                                <Col lg={3} md={4} sm={6} key={item.id}>
                                    <Item className="featured-card">
                                        <a href={`/salon-detail/${item.id}`} className="stretched-link"></a>
                                        {/* <a href={item?.subDomain ? "https://"+item?.subDomain+".retainoo.com/#/public/salon-info/"+item?.id+"?token="+(getKey() ? getKey() : "") : item?.website} target="_blank" className="stretched-link"></a> */}
                                        <div className="image" style={{backgroundImage: `url(${url(item)})`}}>
                                            {item.subscriptionType === "PREMIUM" ? (<div className="label popular-label">FEATURED</div>) : ""}
                                            {/* <div className="label popular-label">FEATURED</div> */}
                                            <div className="label">{salonType(item?.sex)}</div>
                                            {/* <img src={item.salonProfile && item.salonProfile.includes('default') ? DummyProfile : (item.salonProfile ? item.salonProfile : DummyProfile)} alt="img" /> */}
                                        </div>
                                        <div className="grid mt-2">
                                        <div className='text-truncate' style={{display:'flex', alignItems: 'center',justifyContent:'space-between', gap:10}}>
                                            <h5 className="mb-1 text-truncate">{item.legalName}</h5>
                                            <span className="h-list" style={{marginBottom:'10px'}}>
                                                {
                                                    item?.reviewAverage > 0 ?    <div className="font-weight-600 rating-start"><AiFillStar /><span>{item?.reviewAverage?.toFixed(1)}</span> <span className="text-grey">({item?.ratingCount})</span></div>
                                                    : <div className="font-weight-600 rating-start">0 reviews</div>
                                                }
                                                </span>
                                        </div>
                                            <h6 className="text-grey text-truncate">{item?.addressLine2 ? (item?.addressLine1+item?.addressLine2+', '+item?.suburb+', '+item?.state) : item?.addressLine1+', '+item?.suburb+', '+item?.state}</h6>
                                           
                                            {/* <div className="h-list mt-2">
                                                <div className="font-weight-600 rating-start"><ReactSVG src={process.env.PUBLIC_URL + StarIcon} /><span>4.5</span></div>
                                                <div>145 ratings</div>
                                            </div> */}
                                        </div>
                                        
                                    </Item>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </section>
        :   
            // <strong>{searching ? "Searching..." : "Unfortunately we could not find any salons for you at this time. Be sure to check again soon."}</strong>
            <></>
        )
    );
}

export default RetainooFeatured;
