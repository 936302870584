import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ArrowRight from '../../assets/icons/arrow-up.svg'
import LocationSvg from '../../assets/icons/location.svg'

const Item = styled.div`
    position: fixed; left: 0; top: 0; width: 100%; height: 100vh; background: #fff; z-index: 89; padding: 20px; display: flex; flex-direction: column; grid-gap: 14px;

    .wrap-icon .icon {left: 5px;}
    .wrap-icon .form-control {padding-left: 45px; box-shadow: 0px 6px 12px rgba(0,0,0,.05) !important}
    .wrap-icon svg {width: 16px;}

    .search-salons {
        .icon {left: 10px;}
        svg {width: 24px;}
    }

`;

const SearchMoileMenu = (props) => {

    return (
        <Item className={`search-view`}>
            <div className="wrap-icon search-salons">
                <input type="text" className="form-control" placeholder="Search for a service or venue" />
                <div className="icon-rounded icon" onClick={props.handleClick}>
                    <ReactSVG className="svg-icon rotate-180" src={ArrowRight} />
                </div>
            </div>
            {/* <div className="wrap-icon">
                <input type="text" className="form-control" placeholder="Location" />
                <div className="icon-rounded icon">
                    <ReactSVG className="svg-icon" src={LocationSvg} />
                </div>
            </div>
            <div className="suggestions">
                <Link to="#" className="label label-categories">All Categories</Link>
                <Link to="#" className="label label-categories">Beauty Salon</Link>
                <Link to="#" className="label label-categories">Hair Salon</Link>
                <Link to="#" className="label label-categories">Nail Salon</Link>
                <Link to="#" className="label label-categories">Massage</Link>
            </div> */}
        </Item>
    );
}

export default SearchMoileMenu;
