import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import AppleStore from '../assets/images/apple_store.png';
import GoogleStore from '../assets/images/google_store.png';

// assets
import SiteLOGO from '../assets/images/logo-2.png'
import CapterraImg from '../assets/images/capterra-5star-1.png'
import FaceBookIcon from '../assets/icons/facebook.svg'
import InstagramSVG from '../assets/icons/instagram.svg'
import EnvelopeSVG from '../assets/icons/envelope.svg'
import SVGPhone from '../assets/icons/phone.svg'

const Item = styled.div`
    background: #0d2753; color: #fff; padding: 40px 0 0;
    
    * {line-height: 1.4;}
    a:hover,
    a:focus {color: #ef4060 !important}
    a:hover svg,
    a:focus svg {color: #ef4060 !important}
    
    .logo img {max-width: 220px;}
    .img {max-width: 170px;}

    .links {display: grid; grid-gap: 10px;}
    .links a {font-size: 17px; font-weight: 500; color: #fff;}
    .flex svg {color: #fff; margin-right: 10px;}

    .h-list {grid-gap: 15px;}
    .grid {grid-gap: 20px;}
    .pl-2 {padding-left: 15px;}

    .icon-rounded {background: #fff; color: #4285f4;}
    svg {width: 20px;}
    .fb-icon svg {width: 14px !important;}

    .footer-bar {
        background: #0a1f45; padding: 20px 0; margin-top: 20px; font-size: 18px; font-weight: 500;
        .h-list {
            font-size: 14px;
            grid-gap: 14px;
        }
        a {
            font-size: 14px;
            color: #fff;
        }
    }

    @media screen and (max-width: 1500px){
        .logo img {max-width: 180px;}
        .img {max-width: 140px;}

        p,
        .links a {font-size: 14px; margin: 0;}
        
        .grid ,
        .links {grid-gap: 8px}

        .footer-bar {font-size: 13px;}

        [class^="col-"]:not(:last-child) {margin-bottom: 40px;}
    }
    @media screen and (max-width: 991px){
        .logo {margin-bottom: 25px;}
        .fr-auto {grid-template-columns: auto; justify-content: center; text-align: center; grid-gap: 20px;}
        .footer-bar {
            .fr-auto {
                display: flex;
                flex-direction: column;
            }
            h6 {
                opacity: 0.5;
                order: 2;
            }
        }
        .footer-bar .h-list {
            display: inline-block;
            a,
            span {
                padding: 10px;
                display: inline-block;
            }
        }
    }

`;

const AppDownloadIcons = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    
    div{
        width: 100%;
    }
    margin-top: 20px;
    a {
        flex: 1;
        img {
            width: 100%;
        }
    }
    @media screen and (max-width: 767px){
        flex-direction: column;
        margin-top: 10px;
        a {
            img {
                width: 150px;
            }
        }
    }
    @media screen and (max-width: 600px){
        flex-direction: row;

        a {
            img {
                width: 100%;
            }
        }
    }
`;

const Footer = () => {
    const [menu, SetMenu] = useState(false);
    const ToggleAction = () => {
        SetMenu(!menu)
    }

    return (
        <Item className="site-footer">
            <div className="container">
                <Row>
                    <Col lg={4} md={4} sm={12}>
                        <div className="grid">
                            <Link to="#" className="logo mb-3">
                                <img src={process.env.PUBLIC_URL + SiteLOGO} alt="img" />
                            </Link>
                            <h5 className="font-weight-400 mb-2">A product by RETENTION SYSTEMS PTY LTD.</h5>
                            <div className="mt-4 mb-2">
                                <h5>Follow Us On</h5>
                                <div className="h-list mb-3">
                                    <a href='https://www.facebook.com/Retainoo/' className="icon-rounded" target="_blank">
                                        <ReactSVG className="fb-icon" src={process.env.PUBLIC_URL + FaceBookIcon} alt="img" />
                                    </a>
                                    <a href='https://www.instagram.com/retainoo/' className="icon-rounded" target="_blank">
                                        <ReactSVG src={process.env.PUBLIC_URL + InstagramSVG} alt="img" />
                                    </a>
                                </div>
                            </div>
                            <img className="img" src={process.env.PUBLIC_URL + CapterraImg} alt="img" />
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <div className="grid">
                            <h5>Retainoo</h5>
                            <div className="links">
                                {/* <a target="_blank" href="https://www.retainoo.com/why-retainoo/">Why Retainoo</a>
                                <a target="_blank" href="https://www.retainoo.com/features-overview/">Features</a>
                                <a target="_blank" href="https://www.retainoo.com/pricing/">Pricing</a> */}
                                <a target="_blank" href="https://partner.retainoo.com/blogs/">Blogs</a>
                                <a target="_blank" href="https://partner.retainoo.com/support/">Support</a>
                                <a target="_blank" href="https://partner.retainoo.com/contact-us/">Contact Us</a>
                                <a target="_blank" href="https://partner.retainoo.com/"><h5 className="mt-4">For Hair & Beauty Service Providers</h5></a>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                        <div className="grid">
                            <h5>Products</h5>
                            <div className="grid">
                                <div className="links mb-2">
                                    <a target="_blank" href="https://partner.retainoo.com/">Retainoo</a>
                                    <a target="_blank" href="https://partner.retainoo.com/features/" className="pl-2">Salon Management Software & App Platform</a>
                                </div>
                                <div className="links">
                                    <a target="_blank" href="https://grow.retainoo.com/">Retainoo Grow</a>
                                    <a target="_blank" href="https://grow.retainoo.com/" className="pl-2">Listings & Reviews Optimization Solution</a>
                                </div>
                            </div>
                            <AppDownloadIcons>

                                <div>
                                    <a href="https://apps.apple.com/au/app/retainoo-salon-app/id1448167947" target="_blank"><img src={AppleStore} /></a>
                                </div>
                                <div>
                                    <a href="https://play.google.com/store/apps/details?id=com.retainoo&hl=en&gl=US" target="_blank"><img src={GoogleStore} /></a>
                                </div>
                            </AppDownloadIcons>
                        </div>
                    </Col>
                    {/* <Col lg={3} md={3} sm={6}>
                        <div className="grid">
                            <h5>Contact Info</h5>
                            <div className="grid">
                                <h5 className="font-weight-400">
                                    Suite 537 <br />
                                    585 Little Collins Street <br />
                                    MELBOURNE, VIC, 3000 <br />
                                    AUSTRALIA
                                </h5>
                                <div className="links">
                                    <a target="_blank" href="mailto:info@retainoo.com" className="flex mb-1"><ReactSVG src={process.env.PUBLIC_URL + EnvelopeSVG} alt="img" /> info@retainoo.com</a>
                                    <a href="tel:1300640227" className="flex"><ReactSVG src={process.env.PUBLIC_URL + SVGPhone} alt="img" /> 1300640227</a>
                                </div>
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </div>
            <div className="footer-bar">
                <div className="container">
                    <div className="fr-auto">
                        <h6 className="font-weight-500 m-0">Copyright 2022 © Retainoo | All Rights Reserved.</h6>
                        <div>
                            <div className="h-list">
                                <a target="_blank" href="https://partner.retainoo.com/terms-of-service/">Terms of Service</a>| <a target="_blank" href="https://partner.retainoo.com/privacy-policy/">Privacy Policy</a> | <a target="_blank" href="https://partner.retainoo.com/terms-of-use/">Terms of Use</a>
                                | <span>ABN 51 621 453 049</span> |
                                <span>ACN 621 453 049</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Item>
    );
}

export default Footer;
