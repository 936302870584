import React, {useState, useEffect} from 'react';
import SearchResultCard from '../Components/SearchCard';
import { getNonFeaturedSalons } from '../Common/requests';
import { SALONS_LIST_SIZE } from '../Common/constants';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Slider from "react-slick";
import NotFound from '../assets/images/not-found.png';

const OtherSalons = () => {
    const isTab = useMediaQuery({ query: '(max-width: 1024px)' });
    const [searching, setSearching]= useState(true);
    const [salons, setSalons] = useState([]);

    const settings = {
        //  dots: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                },
            },
        ],
    };

    async function fetchSalonsList() {
        setSearching(true);
        const response = await getNonFeaturedSalons();
        setSearching(false);
        setSalons(response?.featuredSaloons);
    }

    useEffect(()=>{
        fetchSalonsList();
    },[])

    const sortOrder = ['PREMIUM','PREMIUM_PLAN_YEARLY','PREMIUM_PLAN_MONTHLY','BASIC_PLAN_YEARLY','BASIC_PLAN_MONTHLY','FREE'];
    const sortSalonList = (arr, desiredOrder) => {
        const orderForIndexVals = desiredOrder.slice(0).reverse();
        arr?.sort((a, b) => {
          const aIndex = -orderForIndexVals.indexOf(a.subscriptionType);
          const bIndex = -orderForIndexVals.indexOf(b.subscriptionType);
          return aIndex - bIndex;
        });
    }

    sortSalonList(salons, sortOrder);

    return (
        <>
            {salons?.length > 0 ? 
                <div className="search-page">
                    <section className="search-results">
                        <div className="container">
                            <h3 className="section-title">Other Venues</h3>
                            {searching ? "" :
                                <>
                                    {isTab ? 
                                        <Slider {...settings}>
                                        {
                                            salons?.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <SearchResultCard item={item}/>
                                                </div>
                                            ))
                                        }
                                        </Slider> 
                                    :
                                        <>
                                            <div className="flex">
                                                <div className="results">
                                                {
                                                    salons?.map((item, index) => (
                                                        <div className="item" key={index}>
                                                            <SearchResultCard item={item}/>
                                                        </div>
                                                    ))
                                                }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </section>
                </div>
            :
                <div className="not-found"><img src={NotFound} /> Unfortunately we could not find any salons for you at this time. <br /> Be sure to check again soon.</div>
            }
        </>
    );
}

export default OtherSalons;
