import axios from 'axios'
import { setUser, clearStorage, setToken, getToken, setKey, getKey, getUser } from "../Common/helper";
import { BASE_URL, FEATURED_LIST_SIZE, HOME_PAGE, SIGNIN_PAGE, LOCAL_URL, SALONS_LIST_SIZE } from '../Common/constants';
import { toast } from 'react-toastify';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization =  `Bearer ${getToken()}`;
    return config;
});

axios.defaults.headers = {
    "content-type": "application/json",
    'agent': 'Web'
};

const responseSuccessHandler = response => {
    return response;
};
  
const responseErrorHandler = error => {
    if (error.response?.status === 401 || error.response?.data.message === 'unauthorized') {
        clearStorage();
        setTimeout(()=>{
            if(window.location.href !== SIGNIN_PAGE){
                window.location = SIGNIN_PAGE;
            }
        },2000)
    }
    return Promise.reject(error);
}

axios.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
);

// AUTHENTICATION
var CryptoJS = require("crypto-js");
let text = "asdf";
var result = '';
var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
var charactersLength = characters.length;
for ( var i = 0; i < 8; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
}
text = result;

export function loginRequest(data){
    axios.get(`${BASE_URL}/common/web-login`,{
        auth: {
            username: data.email ? data.email : data.mobilePhone,
            password: data.password
        }
    })
    .then(response => {        
        var encrypted = CryptoJS.AES.encrypt(data.password, text);
        var decrypt = CryptoJS.AES.decrypt(encrypted, text);            
        let _key = {username:data.email ? data.email : data.mobilePhone, userid:response.data.user.id , _key:encrypted.toString(), _retainoo:text};
    
        setKey(JSON.stringify(_key));
        setToken(response.headers["auth-token"]);
        return response.data
    })
    .then(data => {
        setUser(JSON.stringify(data.user));
        window.location = HOME_PAGE;
    })
    .catch(error => {
        toast.error("Username or Password is incorrect. Please try again.")
        console.log(error)
    })
}

export function signupRequest(data){
    axios.post(`${BASE_URL}/web-register-customer`,data)
    .then(response => {
        loginRequest(data);
        return response.data
    })
    .catch(error => {
        toast.error(error?.response?.data?.errorMessage)
        console.log(error)
    })
}

export async function passwordResetRequest(data){
    try{
        return await axios.post(`${BASE_URL}/forget-password`,data)
    }catch(error){
        toast.error(error?.response?.data?.errorMessage)
        console.log(error)
    }
}

export async function changePasswordRequest(data){
    try{
        return await axios.post(`${BASE_URL}/set-password`,data)
    }catch(error){
        toast.error(error?.response?.data?.errorMessage)
        console.log(error)
    }
}

// USER

export async function getUserData() {
    return await axios
    .get(`${BASE_URL}/customer/get-customer`)
    .then(response => {
        return response.data;
    })
    .catch(error => {
        console.log(error)
    })
}

export async function updateUserData(data) {
    return await axios
    .post(`${BASE_URL}/customer/update-customer`,data)
    .then(response => {
        let _key = JSON.parse(getKey());
        _key.username = data.email;
        setKey(JSON.stringify(_key));

        let user = JSON.parse(getUser());
        user.firstName = data.name.firstName;
        user.lastName = data.name.lastName;
        user.mobilePhone = data.mobilePhone;
        setUser(JSON.stringify(user));
        
        return response;
    })
    .catch(error => {
        toast.error(error?.response?.data?.errorMessage)
        console.log(error)
    })
}

// SALON

export async function getFeatureSalons() {
    return await axios
    .get(`${BASE_URL}/get-featured-saloon?size=${FEATURED_LIST_SIZE}`)
    .then(response => {
        return response.data
    })
    .catch(error => {
        console.log(error)
    })
}

export async function getNonFeaturedSalons() {
    return await axios
    .get(`${BASE_URL}/get-non-featured-saloon?size=${SALONS_LIST_SIZE}`)
    .then(response => {
        return response.data
    })
    .catch(error => {
        console.log(error)
    })
}

export async function getSalonsList(data) {
    return await axios
    .get(`${BASE_URL}/get-saloon-list?name=${data.name}&gender=${data.gender}&category=${data.category}&page=${data.page}&size=${data.size}`)
    .then(response => {
        return response.data
    })
    .catch(error => {
        console.log(error)
    })
}

export async function getSalonReviews(id) {
    return await axios
    .get(`${BASE_URL}/appointment/web/get-salon-reviews?businessId=${id}`)
    .then(response => {
        return response.data
    })
    .catch(error => {
        console.log(error)
    })
}

export async function getSalonDetails(id) {
    return await axios
    .get(`${BASE_URL}/appointment/web/get-salon-info?businessId=${id}`)
    .then(response => {
        return response.data
    })
    .catch(error => {
        console.log(error)
    })
}

export async function getBusinessCategories() {
    return await axios
    .get(`${BASE_URL}/get-business-categories`)
    .then(response => {
        return response.data
    })
    .catch(error => {
        console.log(error)
    })
}

export async function getPromotionsList(data) {
    return await axios
    .get(`${BASE_URL}/get-promos-market`)
    .then(response => {
        return response.data
    })
    .catch(error => {
        console.log(error)
    })
}