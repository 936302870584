import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link, useLocation } from 'react-router-dom';
import { changePasswordRequest } from '../../Common/requests'
import ArrowSVG from '../../assets/icons/arrow-up.svg'
import SimpleReactValidator from "simple-react-validator";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useLayoutEffect } from 'react';

const ForgotPassword = () => {
    const [validator] = useState(new SimpleReactValidator({
        messages: {
            in: "Confirm password should be same as password"
        }
    }));
    const [disabled, setDisabled] = useState(false);
    const [, forceUpdate] = useState();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [isPassowrdReset, setIsPassowrdReset] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showCPassword, setShowCPassword] = useState(false)

    const location = useLocation()

    useLayoutEffect(() => {
        let query = new URLSearchParams(location.search);
        const id = query.get('id')
        if (id)
            setToken(id)

    }, []);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const handleLogin = async (evt) => {
        if (typeof evt !== "undefined" && evt != null) {
            evt.preventDefault();
        }
        if (!validator.allValid()) {
            validator.showMessages();
            forceUpdate(1);
            setDisabled(false);
        } else {
            let data = { token, newPassword: password };
            setDisabled(true)
            try {
                const response = await changePasswordRequest(data);
                if (response.status === 200) {
                    setPassword("");
                    setConfirmPassword("");
                    setDisabled(false);
                    setIsPassowrdReset(true);
                }
            } catch (err) {
                console.log(err);
            }
        }
    }



    return (
        token ? <div className="auth-page">
            <div className="container">
                <div className="block-content inner-head">
                    <Link className="icon" to="/login">
                        <ReactSVG src={process.env.PUBLIC_URL + ArrowSVG} />
                    </Link>
                </div>

                {isPassowrdReset ? <div className="block-content content-holder text-center">
                    <h4>
                        Your Password Has Been Reset Successfully!
                        <Link className="icon" to="/login">
                            <div className="button-holder mt-3">
                                <button type="button" className="btn btn-secondary">Login</button>
                            </div>
                        </Link>

                    </h4>

                </div> :
                    <div className="block-content content-holder">
                        <h2>
                            Change password
                        </h2>
                        <p>
                            Please change your password
                        </p>
                        <form onSubmit={handleLogin} noValidate>
                            <div className="form-group">
                                <label>New Password</label>
                                <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Enter new password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                {validator.message(
                                    "password",
                                    password,
                                    "required|min:8",
                                    { className: "text-danger" }
                                )}
                                <span className="form-icon" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                </span>
                            </div>
                            <div className="form-group">
                                <label>Confirm Password</label>
                                <input type={showCPassword ? "text" : "password"} className="form-control" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                                {validator.message(
                                    "confirmPassword",
                                    confirmPassword,
                                    `required|in:${password}`,
                                    { className: "text-danger" }
                                )}
                                <span className="form-icon" onClick={() => setShowCPassword(!showCPassword)}>
                                    {showCPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                </span>
                            </div>
                            <div className="button-holder mt-3">
                                <button type="submit" className="btn btn-secondary" disabled={disabled}>Change Password</button>
                            </div>
                        </form>
                    </div>
                }
                <h6 className="text-grey text-center"> &copy; 2022 Retainoo.com</h6>
            </div>
        </div> : <div className="auth-page">
            <div className="container">
                <div className="block-content content-holder text-center">
                    <h4>
                        Unauthorized, Please Verify Yourself!
                    </h4>

                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
