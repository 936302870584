import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import SearchSVG from '../assets/icons/search.svg'
import BannerImg from '../assets/images/banner.png'

const IndexBanner = () => {
    return (
        <section className="home-banner" style={{backgroundImage: `URL(${BannerImg})`}}>
            <div className="container">
                <div className="caption-box">
                    <h2>Explore the best hair &amp; beauty service providers nearby and search for discounts and deals.</h2>
                    <Link to="/search" className="btn btn-secondary">
                        <ReactSVG className="icon" src={SearchSVG} />
                        Search &amp; Book Now
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default IndexBanner;
