import React, { useEffect } from 'react';
import AboutSubscription from '../Components/AboutSubscription';
import IndexBanner from '../Components/IndexBanner';
import RetainooFeatured from '../Components/RetainooFeatured';
import RetainooPromotion from '../Components/RetainooFeaturedPost'
import OtherSalons from './OtherSalons';

const Home = () => {
    
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div className="index-page">
            <IndexBanner />
            <RetainooFeatured />
            <RetainooPromotion />
            <OtherSalons />
            <AboutSubscription />
        </div>
    );
}

export default Home;
