import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { passwordResetRequest } from '../../Common/requests'
import ArrowSVG from '../../assets/icons/arrow-up.svg'
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';

const ForgotPassword = () => {
    const [validator] = useState(new SimpleReactValidator());
    const [disabled, setDisabled] = useState(false);
    const [, forceUpdate] = useState();

    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false)

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    function updateStates() {
        toast.error("Username or Phone is required.")
        forceUpdate(1);
        setDisabled(false);
    }

    const handleLogin = async (evt) => {
        if (typeof evt !== "undefined" && evt != null) {
            evt.preventDefault();
        }
        let data = { email: username, phone, marketplace: true };

        if (!username) {
            if (!phone) {
                updateStates()
                evt.preventDefault();
                return
            } else if (phone && !username) {
                setDisabled(true);
                try {
                    const response = await passwordResetRequest(data);
                    if (response.status === 200) {
                        setDisabled(false);
                        setIsEmailSent(true)
                    }
                } catch (e) {
                    setDisabled(false);
                }
            } else {
                updateStates()
                evt.preventDefault();
                return
            }
        } else {
            setDisabled(true);
            try {
                const response = await passwordResetRequest(data);
                if (response.status === 200) {
                    setDisabled(false);
                    setIsEmailSent(true)
                }
            } catch (e) {
                setDisabled(false);
            }
        }
    }

    return (
        <div className="auth-page">
            <div className="container">
                <div className="block-content inner-head">
                    <Link className="icon" to="/login">
                        <ReactSVG src={process.env.PUBLIC_URL + ArrowSVG} />
                    </Link>
                </div>

                {isEmailSent ? <div className="block-content content-holder text-center">
                    <h2>
                        {username ? "Email Sent Successfully !" : "OTP Sent"}
                    </h2>
                    <p>
                        {username ? <span>An email has been sent to <b>{username}</b></span> : <span>Opt sent to <b>{phone}</b></span>}
                        {username ? " Follow the link in email to reset password." : "Please use the otp for password reset"}
                    </p>
                </div> :
                    <div className="block-content content-holder">
                        <h2>
                            Forgot password
                        </h2>
                        <p>
                            Enter your email address or Phone number to recover your password.
                        </p>
                        <form onSubmit={handleLogin} noValidate>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" className="form-control" placeholder="Email" value={username} onChange={(e) => { setUsername(e.target.value); setPhone("") }} />
                            </div>
                            <h5 className="text-center">
                                OR
                            </h5>
                            <div className="form-group">
                                <label>Phone</label>
                                <input type="text" className="form-control" placeholder="Phone" value={phone} onChange={(e) => { setPhone(e.target.value); setUsername("") }} />
                            </div>
                            <div className="button-holder mt-3">
                                <button type="submit" className="btn btn-secondary" disabled={disabled}>Reset Password</button>
                            </div>
                        </form>
                    </div>
                }
                <h6 className="text-grey text-center"> &copy; 2022 Retainoo.com</h6>
            </div>
        </div>
    );
}

export default ForgotPassword;
