import React, { useCallback, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import StarsSVG from "../assets/icons/star.svg"
import VerifiedSVG from "../assets/icons/verified.svg"
import SalonPic from "../assets/images/salon-pic.webp";
import UserImage from "../assets/images/user.png";
import {Row, Col, Nav, Tab, Pagination, ProgressBar } from "react-bootstrap";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import { getSalonDetails, getSalonReviews } from "../Common/requests";
import backgroundImage from '../assets/images/online-booking-image.jpg'
import { getKey } from "../Common/helper";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css'
import moment from "moment";

const SalonDetail = () => {
    const { id } = useParams();
    const [salonDetails, setSalonDetails] = useState([]);
    const [salonReviews, setSalonReviews] = useState([]);
    const [salonReviewsCopy, setSalonReviewsCopy] = useState([]);
    const [salonTimings, setSalonTimings] = useState([]);
    const [salonServices, setSalonServices] = useState([]);
    const [selectedRatingForFilter, setSelectedRatingForFilter] = useState([]);
    const [reviewAverage, setReviewAverage] = useState(null);
    const [shouldReviewsSectionShow,setShouldReviewsSectionShow] = useState(false);
    const weekday = ["SUNDAY","MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY"];
    const date = new Date();
    let day = weekday[date.getDay()];
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    let active = 2;
    let items = [];
    for (let number = 1; number <= 5; number++) {
        items.push(
            <Pagination.Item key={number} active={number === active}>
                {number}
            </Pagination.Item>,
        );
    };

    async function fetchSalonDetails() {
        const response = await getSalonDetails(id);
        const responseReviews = await getSalonReviews(id);
        setSalonDetails(response?.salon);
        setShouldReviewsSectionShow(response?.salon.reviewsListing)
        setSalonTimings(response?.salonCalendar.repeatable);
        setSalonServices(response?.salonServices);
        setSalonReviews(responseReviews.staffRating.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)))
        setSalonReviewsCopy(responseReviews.staffRating.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)))
        setReviewAverage(responseReviews.reviewAverage)
        setSelectedRatingForFilter([
            {stars:50,count:responseReviews?.staffRating?.filter(review=>review.rating===50).length,id:"filter-rating-1",isChecked: false},
            {stars:40,count:responseReviews?.staffRating?.filter(review=>review.rating===40).length,id:"filter-rating-2",isChecked: false},
            {stars:30,count:responseReviews?.staffRating?.filter(review=>review.rating===30).length,id:"filter-rating-3",isChecked: false},
            {stars:20,count:responseReviews?.staffRating?.filter(review=>review.rating===20).length,id:"filter-rating-4",isChecked: false},
            {stars:10,count:responseReviews?.staffRating?.filter(review=>review.rating===10).length,id:"filter-rating-5",isChecked: false},
        ])

    }

    useEffect(() => {
        window.scroll(0, 0);
        if(id)
            fetchSalonDetails();
    },[])

    const genderType = (type) =>{
        if(type === "male")
            return "Male Only";
        else if(type === "female")
            return "Female Only";
        else
            return "Unisex";
    }

    const timings = () => {
        return salonTimings?.map((time) => {
            return  <li className={day === time.day ? 'highlight-day' : ''}>
                        <div>{time.day}</div>
                        <div>{time.start} - {time.end}</div>
                    </li>
        });
    }
    
    const categories = salonServices?.map(item => item.categoryName).filter((value, index, self) => self.indexOf(value) === index)

    const serviceCategories = () => {
        return categories?.map((item, index) => {
            return  <Nav.Item>
                        <Nav.Link eventKey={index+1}>{item}</Nav.Link>
                    </Nav.Item>
        });
    }

    const services = () => {
        return categories?.map((category) => {
            return salonServices?.filter(function (item){
                return item.categoryName === category
            })
        })
    }

    const viewServices = () => {
        return services()?.map((service, index) => {
            return  <Tab.Pane eventKey={index+1}>
                        <ul>
                            {service.map((item, index) => {
                                return  <li key={index}>
                                            <input type="checkbox" id={"ser"+index+item.serviceName} />
                                            <label htmlFor={"ser"+index+item.serviceName}>
                                                <strong>{item.serviceName}</strong>
                                                <span className="duration">{item.duration} Mins</span>
                                                <div className="price-box">
                                                    {item.discountedPrice > 0.0 ? <div className="disc-price">{salonDetails.currency} {item.discountedPrice}</div> : <></> }
                                                    <div className={item.discountedPrice  > 0.0 ? "disc-price-other" : "ser-price"}>{item.discountedPrice > 0.0 ?<>{salonDetails.currency} {item.price}</> : <>{salonDetails.currency} {item.price}</> }</div>
                                                </div>
                                            </label>
                                        </li>
                            })}
                        </ul>
                    </Tab.Pane>

        })
    }


    // Funtion to handle the checkboxes selction change.
    const handleRatingFilterChange = (evt) => {
       setSelectedRatingForFilter(selectedRatingForFilter?.map(filter=>{
            if(filter.id===evt.target.id) filter.isChecked = evt.target.checked
            return filter
       }))
        if(!selectedRatingForFilter?.map(filter=>filter.isChecked).includes(true)){
            setSalonReviews(salonReviewsCopy)
        }else{
            setSalonReviews(
                salonReviewsCopy
                    ?.filter(salonReview=>selectedRatingForFilter
                    ?.filter(filter=>filter.isChecked===true)
                    .map(selectedFilter=>selectedFilter.stars)
                    .includes(salonReview.rating))
                )
        }
    }
    // Function to generate rating start
    const generateStarsRating = useCallback((rating) =>{
        const stars = []
        for(let i=0; i<rating; i++) {
            stars.push(<li className="filled"><AiFillStar /></li>)
        }
        for(let i=5; i>rating; i--) {
            stars.push(<li><AiFillStar /></li>)
        }
        return  <ul className="d-flex">{stars.map(star=>star)}</ul>
    },[selectedRatingForFilter,salonReviews])

    return (
        <div className="salon-detail">
            <div className="container">
                <div className="top-header">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="text-holder">
                                <h1>{salonDetails?.legalName}</h1>
                                {salonDetails?.address ?
                                    <p className="address">{salonDetails?.address?.addressLine2 ? (salonDetails?.address?.addressLine1+salonDetails?.address?.addressLine2+', '+salonDetails?.address?.suburb+', '+salonDetails?.address?.state) : salonDetails?.address?.addressLine1+', '+salonDetails?.address?.suburb+', '+salonDetails?.address?.state}</p>
                                :
                                    ''
                                }
                            <a href="#reviews">
                                <div className="ratings">
                                
                                    <Rater style={{display: 'flex', gap: "3px"}} total={5} interactive={false} rating={reviewAverage}  />
                                    {reviewAverage}
                                    <span className="text-muted">({salonReviewsCopy.length} {salonReviewsCopy.length > 1 ? "reviews" : "reviews"})</span>
                                </div>
                            </a>
                                <a href={salonDetails?.subDomain ? "https://"+salonDetails?.subDomain+".retainoo.com/#/public/salon-info/"+salonDetails?.id+"?token="+(getKey() ? getKey() : null) : salonDetails?.website} target="_blank" className="btn btn-secondary btn-block">Book Now</a>
                                {/* <button className="btn btn-secondary btn-block">Book Now</button> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="image-slider">
                                <Slider {...settings}>
                                    <div>
                                        <img src={salonDetails?.salonBackgroundProfile && salonDetails?.salonBackgroundProfile.includes('default') ? backgroundImage : (salonDetails?.salonBackgroundProfile ? salonDetails?.salonBackgroundProfile : backgroundImage)} />
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fixed-salon-section">
                    <div>
                        <strong>{salonDetails?.legalName}</strong>
                        <div className="ratings">
                            <Rater style={{display: 'flex', gap: "3px"}} total={5} interactive={false} rating={reviewAverage}  />
                            {reviewAverage}
                            <span className="text-muted">({salonReviewsCopy.length} {salonReviewsCopy.length > 1 ? "reviews" : "reviews"})</span>
                        </div>
                    </div>
                    <div>
                        <span className="available-services">{salonServices?.length} Services Available &ensp;</span>
                        <a href={salonDetails?.subDomain ? "https://"+salonDetails?.subDomain+".retainoo.com/#/public/salon-info/"+salonDetails?.id+"?token="+(getKey() ? getKey() : null) : salonDetails?.website} target="_blank" className="btn btn-secondary btn-block">Book Now</a>
                    </div>
                </div>
                <div className="about-salon">
                    <div className="row justify-content-between">
                        <div className="col-lg-7">
                            <div className="box-card">
                                <h2>{salonDetails?.legalName}</h2>
                                <ul className="features">
                                    <li>Gender: {genderType(salonDetails?.sex)}</li>
                                </ul>
                                <p>{salonDetails?.description}</p>
                            </div>
                            <div className="box-card location">
                                <h2>Location</h2>
                                {salonDetails?.address ?
                                    <p>{salonDetails?.address?.addressLine2 ? (salonDetails?.address?.addressLine1+salonDetails?.address?.addressLine2+', '+salonDetails?.address?.suburb+', '+salonDetails?.address?.state) : salonDetails?.address?.addressLine1+', '+salonDetails?.address?.suburb+', '+salonDetails?.address?.state}
                                        {(salonDetails?.address?.latitude && salonDetails?.address?.longitude) ? <a className="btn btn-link justify-content-start" href={"https://www.google.com/maps/search/?api=1&query="+salonDetails?.address?.latitude+"%2C"+salonDetails?.address?.longitude} target="_blank">See Directions</a> : "" }
                                    </p>
                                :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='box-card'>
                                <h2>Timings</h2>
                                <ul className='timings'>
                                    {timings()}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {salonServices?.length > 0 ? 
                        <div className='salon-services'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='box-card'>
                                        <h2>Services</h2>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey={1}>
                                            <Row>
                                                <Col lg={3}>
                                                <Nav variant="pills" className="flex-column">
                                                    {serviceCategories()}
                                                </Nav>
                                                </Col>
                                                <Col lg={9}>
                                                    <div className='services-list'>
                                                        <Tab.Content>
                                                            {viewServices()}
                                                        </Tab.Content>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <></>
                    }
                    <div  id="reviews"></div><br/><br/><br/>
                  { shouldReviewsSectionShow &&  <div className="reviews">
                        <div className="row">
                            <div className="col-lg-4">
                                <h2>Reviews</h2>
                                <div className="review-stars d-flex align-items-center">                                
                                    <Rater style={{display: 'flex', gap: "3px"}} total={5} interactive={false} rating={reviewAverage}  />
                                    {reviewAverage}
                                    <span className="text-muted">({salonReviewsCopy.length} {salonReviewsCopy.length > 1 ? "reviews" : "reviews"})</span>
                                </div>
                                <div className="reviews-trust d-flex align-items-center mb-4">
                                    <VscWorkspaceTrusted />
                                    <div>
                                        <strong>Reviews you can trust</strong>
                                        <p>All reviews are provided by genuine customers who have purchased a service or a product from this business</p>
                                    </div>
                                </div>
                                <div className="filter-by-ratings">
                                    {selectedRatingForFilter?.map((filterCheckbox)=>(
                                        <div className="checkbox-holder" key={filterCheckbox.id}>
                                            <div className="custom-checkbox">
                                                <input type="checkbox" id={filterCheckbox.id} onChange={handleRatingFilterChange} checked={filterCheckbox.isChecked} />
                                                <label for={filterCheckbox.id}>
                                                    <div className="d-flex align-items-center">
                                                        {generateStarsRating(Math.round(filterCheckbox.stars/10))}
                                                        <ProgressBar now={Math.round((filterCheckbox.count/salonReviewsCopy.length) * 100)} />
                                                        <div className="review-numbers">{filterCheckbox.count}</div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="review-lists">
                                    {/* {salonReviews?.map((rating, index) => ( */}
                                    {salonReviews?.map((rating, index) => (
                                        <div key={index} className="review-list">
                                            <div className="user-details">
                                                <div className="d-flex align-items-center">
                                                    <div className="initials">
                                                        {rating.customerName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}
                                                    </div>
                                                    <div className="name">
                                                        {rating.customerName}
                                                        <small>
                                                            {
                                                                rating.timestamp
                                                            }
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="user-review-stars">
                                                    {generateStarsRating(Math.round(rating.rating/10))}
                                                </div>
                                            </div>
                                            
                                           {rating.comment.trim() && <div className="user-review-text">
                                                {rating.comment}
                                            </div>}
                                        </div> 
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                  }
                </div>
            </div>
        </div>
    );
}

export default SalonDetail
