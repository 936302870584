import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import userImg from '../assets/images/user.png'
import EditSvg from '../assets/icons/pen.svg'
import { getUserData, updateUserData } from '../Common/requests';
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';

const Profile = () => {
    const [user, setUser] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [mobilePhone, setMobilePhone] = useState(null);
    const [email, setEmail] = useState(null);
    
    const [validator] = useState(new SimpleReactValidator());
    const [disabled, setDisabled] = useState(false);
    const [, forceUpdate] = useState();

    async function fetchUserData() {
        const response = await getUserData();
        setUser(response);
        setFirstName(response.firstName);
        setLastName(response.lastName);
        setMobilePhone(response.mobilePhone);
        setEmail(response.email);
    }
    
    useEffect(()=>{
        fetchUserData();
    },[])

    async function setUserData(evt) {
        if (typeof evt !== "undefined" && evt != null) {
          evt.preventDefault();
        }

        if (!validator.allValid()) {
            validator.showMessages();
            forceUpdate(1);
            setDisabled(false);
        } else if ((email === null || email === "") && (mobilePhone === null || mobilePhone === undefined)) {
            toast.error("Phone Number or Email Address is required for Username.");
        } else {
            setDisabled(true);
            let data = {customerId: user.id, name: {firstName: firstName, lastName: lastName}, mobilePhone: mobilePhone, email: email};
            const response = await updateUserData(data);
            if(response?.status === 200){
                toast.success("Profile updated successfully.")
            }
        }
        
        if (disabled)
            setDisabled(false);
    }

    return (
        <div className="profile-page">
            <div className="container">
                <div className="profile-holder">
                    <div className="image">
                        <img src={userImg} alt="img" />
                        {/* <div className="icon-rounded icon">
                            <ReactSVG className="svg-icon" src={EditSvg} />
                        </div> */}
                    </div>
                    <div className="form-group">
                        <label>First Name</label>
                        <input type='text' className="form-control" value={firstName} onChange={(e)=>setFirstName(e.target.value)} />
                        {validator.message(
                            "first_name",
                            firstName,
                            "required|alpha_space",
                            { className: "text-danger" }
                        )}
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type='text' className="form-control" value={lastName} onChange={(e)=>setLastName(e.target.value)} />
                        {validator.message(
                            "last_name",
                            lastName,
                            "required|alpha_space",
                            { className: "text-danger" }
                        )}
                    </div>
                    <div className="form-group">
                        <label>Phone Number</label>
                        {/* <input type='tel' className="form-control" value={mobilePhone} onChange={(e)=>setMobilePhone(e.target.value)} /> */}
                        <PhoneInput
                            international
                            countryCallingCodeEditable={true}
                            countrySelectProps={{ unicodeFlags: true }}
                            defaultCountry="AU"
                            value={mobilePhone}
                            onChange={(number)=>setMobilePhone(number)}
                            placeholder="Your Phone Number"
                        />
                        {validator.message(
                            "phone",
                            mobilePhone,
                            "phone",
                            { className: "text-danger" }
                        )}
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type='email' className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)} />
                        {validator.message(
                            "email",
                            email,
                            "email",
                            { className: "text-danger" }
                        )}
                    </div>
                    {/* <div className="form-group">
                        <label>Password</label>
                        <input type={'password'} className="form-control" value="123456" />
                    </div> */}
                    <div className="button-holder mt-5 grid">
                        <button className="btn btn-secondary btn-block" onClick={(evt)=>setUserData(evt)}>Update Profile</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
