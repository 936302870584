import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import demoImage from "../assets/images/banner.jpg";
import { getPromotionsList } from "../Common/requests";
import { Link } from "react-router-dom";
import { getKey } from "../Common/helper";
import { promotions } from "../Json/PromotionCardData";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import NotFound from '../assets/images/not-found.png';

const RetainooDeals = () => {
  const isTab = useMediaQuery({ query: '(max-width: 1024px)' })
  const [promotionsList, setPromotionsList] = useState([]);
  const [searching, setSearching] = useState(true);

  async function fetchPromotionsList() {
    const response = await getPromotionsList();
    setPromotionsList(response?.promotions);
    setSearching(false);
  }

  useEffect(() => {
    window.scroll(0, 0);
    setSearching(true);
    fetchPromotionsList();
  }, []);

  const dateString = (date) => {
    return (
      date[0] + "-" + date[1] + "-" + date[2] + " " + date[3] + ":" + date[4]
    );
  };
  const settings = {
    //  dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };


  return (
    <>
      <div className="search-page" id="search-page">
        <section className="search-results">
          <div className="container">
            <h3 className="section-title">Deals</h3>
            {promotionsList?.length > 0 ? (
              <Row>
                {
                  isTab ?
                  <Slider {...settings}>
                      {
                    // iterating the data array
                    promotionsList?.map((item, key) => {
                      return (
                        <>
                          <div className="mb-4">
                            <div
                              className="featured-post-card"
                            >
                              <div className="post-card-outer width-100">
                                  <div>
                                    {/* { backgroundImage: `url(${item.image})` } */}
                                    {/* Image Shown as background */}
                                    <div
                                      className="post-image"
                                      style={{
                                        backgroundImage: `url(${
                                          item.banner ? item.banner : demoImage
                                        })`,
                                      }}
                                    ></div>
                                    {/* Text Data */}
                                    <div className="p-3">
                                      <h4 className="mb-1 featured-card-title line-clamp-2">
                                        {item.title}
                                      </h4>
                                      <p className="featured-card-text line-clamp-2">
                                        {item.description}
                                      </p>
                                      {/* Date Show */}
                                      <div className="dd">
                                        <div className="w-100 mb-2">
                                          <p className="text-small">From</p>
                                          <p className="time-text">
                                            {moment(
                                              dateString(item.startDateTime)
                                            ).format("MMMM Do YYYY,  h:mm:ss a")}
                                          </p>
                                        </div>
                                        <div className="w-100">
                                          <p className="text-small">To</p>
                                          <p className="time-text">
                                            {moment(
                                              dateString(item.endDateTime)
                                            ).format("MMMM Do YYYY, h:mm:ss a")}
                                          </p>
                                        </div>
                                      </div>
                                      {/* Status Badge on Top Left */}
                                      <div className={"post-status"}>
                                        <span className="text-light">
                                          {item.status}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                {/* Post Button */}
                                <div className="m-2">
                                  <Link className="btn btn-outline-light border  text-dark btn-block mb-2" to={`/salon-detail/${item?.business?.id}`}>View Salon</Link>
                                  <a
                                    href={
                                      item?.business?.subDomain
                                        ? "https://" +
                                          item?.business?.subDomain +
                                          ".retainoo.com/#/public/salon-info/" +
                                          item?.business?.id +
                                          "?token=" +
                                          (getKey() ? getKey() : null)
                                        : item?.business?.website
                                    }
                                    target="_blank"
                                    className="btn btn-secondary btn-block custom-button"
                                  >
                                    {" "}
                                    Book Now{" "}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  }
                    </Slider>: 
                    
                <>
                {
                  // iterating the data array
                  promotionsList?.map((item, key) => {
                    return (
                      <>
                        <Col lg={3} md={4} sm={6} className="mb-4">
                          <div
                            className="featured-post-card"
                          >
                            <div className="post-card-outer width-100">
                              <Link to={`/salon-detail/${item?.business?.id}`}>
                                <div>
                                  {/* { backgroundImage: `url(${item.image})` } */}
                                  {/* Image Shown as background */}
                                  <div
                                    className="post-image"
                                    style={{
                                      backgroundImage: `url(${
                                        item.banner ? item.banner : demoImage
                                      })`,
                                    }}
                                  ></div>
                                  {/* Text Data */}
                                  <div className="p-3">
                                    <h4 className="mb-1 featured-card-title line-clamp-2">
                                      {item.title}
                                    </h4>
                                    <p className="featured-card-text line-clamp-2">
                                      {item.description}
                                    </p>
                                    {/* Date Show */}
                                    <div className="dd">
                                      <div className="w-100 mb-2">
                                        <p className="text-small">From</p>
                                        <p className="time-text">
                                          {moment(
                                            dateString(item.startDateTime)
                                          ).format("MMMM Do YYYY,  h:mm:ss a")}
                                        </p>
                                      </div>
                                      <div className="w-100">
                                        <p className="text-small">To</p>
                                        <p className="time-text">
                                          {moment(
                                            dateString(item.endDateTime)
                                          ).format("MMMM Do YYYY, h:mm:ss a")}
                                        </p>
                                      </div>
                                    </div>
                                    {/* Status Badge on Top Left */}
                                    <div className={"post-status"}>
                                      <span className="text-light">
                                        {item.status}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              {/* Post Button */}
                              <div className="m-2">
                                <a
                                  href={
                                    item?.business?.subDomain
                                      ? "https://" +
                                        item?.business?.subDomain +
                                        ".retainoo.com/#/public/salon-info/" +
                                        item?.business?.id +
                                        "?token=" +
                                        (getKey() ? getKey() : null)
                                      : item?.business?.website
                                  }
                                  target="_blank"
                                  className="btn btn-secondary btn-block custom-button"
                                >
                                  {" "}
                                  Book Now{" "}
                                </a>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })
                }
                </>
                }
              </Row>
            ) : (
              <strong>
                {searching ? "Searching..." : <div className="not-found"><img src={NotFound} /> Unfortunately we could not find any deals for you at this time. <br /> Be sure to check again soon.</div>}
              </strong>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default RetainooDeals;
