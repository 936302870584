import React, {useState, useEffect} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { getUser } from './Common/helper';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from './Layout/Header';
import Home from './Feature/Home';
import Signup from './Feature/Auth/Signup';
import Login from './Feature/Auth/Login';
import Footer from './Layout/Footer';
import Search from './Feature/SearchPage';
import Profile from './Feature/Profile';
import SalonDetail from './Feature/SalonDetail';
import RetainooDeals from './Feature/RetainooDealsPage';
import ForgotPassword from './Feature/Auth/ForgotPassword';
import ResetPassword from './Feature/Auth/ResetPassword';

function App() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150);
    });
  }, []);

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/signup" &&
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/forgot-password" &&
    window.location.pathname !== "/account/recover" &&
    !window.location.pathname.includes("/salon-detail") &&
    window.location.pathname !== "/search" &&
    window.location.pathname !== "/deals"
  ) {
    if (getUser() === undefined || getUser() === null) {
      window.location = "/login";
    }
  }

  return (
    <div className={scroll ? "app sticky-header" : "app"}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/account/recover" component={ResetPassword} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/deals" component={RetainooDeals} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/salon-detail/:id" component={SalonDetail} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
