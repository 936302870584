import React, {useState, useEffect} from 'react';
import Select from 'react-select'
import SearchResultCard from '../Components/SearchCard';
import { getBusinessCategories, getSalonsList } from '../Common/requests';
import { SALONS_LIST_SIZE } from '../Common/constants';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import SearchSVG from '../assets/icons/search.svg'
import CrossSVG from '../assets/icons/cross.svg'
import { useMediaQuery } from 'react-responsive';
import Slider from "react-slick";
import NotFound from '../assets/images/not-found.png';

const Item = styled.div`
    z-index: 9999; transition: all .3s ease; width: 100%; background: #fff; display: flex; align-items:center; justify-content: space-between; padding: 19px 40px; box-shadow: 0px 1px 3px rgba(0, 0, 0, .15);
    .logo img {max-width: 140px;}

    .menu-button {
        position: relative; min-width: 85px; cursor: pointer; transition: all .3s ease; font-size: 17px; display: flex; justify-content: flex-end; align-items: center;
    }

    .menu-button,
    a {font-size: 16px; font-weight: 500; color: var(--link-color);}
    .menu-button.active,
    .menu-button:hover,
    a:hover {color: var(--primary)}

    .menu-button {
        .dropdown-menu {padding: 10px; position: absolute; z-index: 600; overflow-y: auto; top: 100%; right: 0px; max-height: calc((100vh - 72px) - 16px); display: block; background: rgb(255, 255, 255); border-radius: 4px; border: 1px solid rgba(16, 25, 40, 0.1); box-shadow: rgba(16, 25, 40, .10) 0px 4px 6px 0px; transition: all 200ms ease 0s; transform: translateY(0px); opacity: 0; pointer-events: none;}
        .dropdown-menu a {font-size: 14px; color: var(--content-color); display: flex; background: none; border: none; text-decoration: none; padding: 0px 16px; margin: 0px; height: 38px; white-space: nowrap; -webkit-box-align: center; align-items: center; width: 100%; outline: none; transition: all 200ms ease 0s;}
        .dropdown-menu a:hover {color: #676767;}
        .dropdown-menu li:last-child a {border-top: 1px solid #e7e8e9; min-height: 44px; margin-top: 4px;}
        .dropdown-menu li:last-child .svg-icon {margin-right: 0;}
        &.active .dropdown-menu { transform: translateY(20px); opacity: 1; pointer-events: all;}
    }

    .profile-dropdown {
        .btn {background: transparent; padding: 0; outline: none; box-shadow: none !important;}
        .dropdown-toggle::after {border-top-color: #000;}
        .show.dropdown .dropdown-toggle::after {transform: rotate(180deg)}

        .dropdown-menu {min-width: 220px; padding: 0; border-color: #e7e7e7; box-shadow: 0px 3px 10px rgba(0,0,0,.10)}
        .dropdown-item {padding: 12px 15px; background: #fff !important;}
        .dropdown-item:not(:last-child) {border-bottom: 1px solid #e7e7e7}
        h6 {margin: 0; font-size: 20px; pointer-events: none; font-weight: 600; padding: 25px 15px !important;}
    }

    @media screen and (max-width: 1440px){
        padding: 15px 20px;
    }
    
    @media screen and (max-width: 767px){
        z-index:999
    }
    @media screen and (max-width: 991px){
        .menu-button,
        a {font-size: 13px;}

        .menu-button {min-width: 75px;}

        .h-list {grid-gap: 15px;}
    }
`;

const Search = () => {
    const isTab = useMediaQuery({ query: '(max-width: 1024px)' });
    const [checked, setchecked]= useState(false);
    const [searching, setSearching]= useState(true);
    const [salons, setSalons] = useState([]);
    const [gender, setGender] = useState('');
    const [name, setName] = useState('');
    const [category, setCategory] = useState(0);

    let location = useLocation();
    const [search, setSearch] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [lockDropdown, setLockDropdown] = useState(false);
    const [categories, setCategories] = useState([]);

    const showMenu = () => setDropdown(!dropdown)
    const HideMenu = () => setDropdown(!dropdown)
    const ShowLocMenu = () => setLockDropdown(!lockDropdown)

    const options = [
        { value: 'Recommended', label: 'Recommended' },
        { value: 'Nearest', label: 'Nearest' },
        { value: 'Top Rated', label: 'Top Rated' }
    ];

    const genderList = [
        { value: '', label: 'All' },
        { value: 'both', label: 'Unisex' },
        { value: 'female', label: 'Female' },
        { value: 'male', label: 'Male' }
    ];    
    async function fetchCategories() {
        const response = await getBusinessCategories();
        setCategories(response)
    }
    
    useEffect(()=>{
        window.scroll(0, 0);
        fetchCategories();
    },[])

    const categoriesList = () => {
        return categories?.map((item)=>{
            return <Link id={item.id} className="label label-categories" onClick={()=>{setCategory(item.id); setName(item.name);}}>{item.name}</Link>
        });
    } 

const settings = {
    //  dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        },
      },
    ],
  };

    async function fetchSalonsList() {
        setSearching(true);
        let data = {
            "name" : (category === 0 ? name : ""),
            "gender" : gender,
            "category" : category,
            "page" : 1,
            "size" : SALONS_LIST_SIZE
        }

        const response = await getSalonsList(data);
        setSearching(false);
        setSalons(response?.featuredSaloons);
    }

    useEffect(()=>{
        if(category !== 0)
            document.querySelector('.search-bar').classList.remove("open");
        fetchSalonsList();
    },[gender, category])

    useEffect(()=>{
        if(name.length >= 3 || name.length === 0){    
            fetchSalonsList()
        }
    },[name, category])

    const sortOrder = ['PREMIUM','PREMIUM_PLAN_YEARLY','PREMIUM_PLAN_MONTHLY','BASIC_PLAN_YEARLY','BASIC_PLAN_MONTHLY','FREE'];
    const sortSalonList = (arr, desiredOrder) => {
        const orderForIndexVals = desiredOrder.slice(0).reverse();
        arr?.sort((a, b) => {
          const aIndex = -orderForIndexVals.indexOf(a.subscriptionType);
          const bIndex = -orderForIndexVals.indexOf(b.subscriptionType);
          return aIndex - bIndex;
        });
    }

    sortSalonList(salons, sortOrder);

    return (
        <>
            <Item className="site-header search-bar">
                <div className="h-list">
                    { location.pathname.match("/search") ?
                        <div className={`search-wrapper`}>
                            
                            <div className={`search-services ${dropdown ? "active" : ""}`}>
                                <div className="wrap-icon">
                                    <input type="text" className="form-control" onFocus={showMenu} onBlur={HideMenu} placeholder="Search for a service or salon" onChange={(e)=>{setName(e.target.value); setCategory(0)}} value={name} />
                                    <div className="closeBtn" style={{display: name ? 'block' : 'none' }}>
                                        <ReactSVG className="svg-icon" src={CrossSVG} onClick={()=>{setCategory(0); setName(''); setDropdown(false);}}/>
                                    </div>
                                    <ReactSVG className="icon" src={SearchSVG} />
                                </div>
                                <div className="search-dropdown">
                                    <div className="suggestions">
                                        <Link id={0} className="label label-categories" onClick={()=>{setCategory(0); setName("");}}>All Categories</Link>
                                        {categoriesList()}
                                    </div>
                                </div>
                            </div>
                            {salons?.length > 0 ? 
                                <>
                                    <h6 className="text-grey m-0 font-size-16">in</h6>
                                    <h6 className="btn-link m-0">{salons[0].country}</h6>
                                </>
                            :
                                <></>
                            }   
                            
                            {/* <div className={`loc-wrapper ${lockDropdown? "active" : ""}`}>
                                <input type="text" className="form-control" onFocus={ShowLocMenu} onBlur={HideLocMenu} placeholder="Find Location" />
                                <div className="search-dropdown">
                                    <div className="suggestions">
                                        {categoriesList()}
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    :
                        ""
                    }   
                </div>
            </Item>
            <div className="search-banner">
                <div className="container">
                    {salons?.length > 0 ?
                        <h2>{salons ? salons.length : "0"} result{salons.length <= 1 ? "" : "s"}{categories[category-1] ? " for "+categories[category-1].name : ""}</h2>
                    :
                        <h2>{categories && categories[category-1] ? ("No results for "+categories[category-1]?.name+".") : ((searching ? "Searching..." : "")) }</h2>
                    }
                    <div className="flex justify-content-between mt-4">
                        <div className="h-list">
                            {/* <Select className="select-picker" placeholder="Recommended" options={options} /> */}
                            <Select className="select-picker" options={genderList} placeholder="Gender" defaultInputValue={genderList[gender]} onChange={(e)=>setGender(e.value)} />
                        </div>
                        {/* <div className="h-list hide-on-mobile">
                            <div className="h-list">
                                <span>Show Map</span>
                                <label className="switch">
                                    <input type="checkbox" onClick={()=>setchecked(!checked)} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="search-page">
                <section className="search-results">
                    <div className="container">
                        {searching ? "" :
                        <>
                            {salons?.length < 1 ? 
                                <div className="not-found"><img src={NotFound} /> Unfortunately we could not find any salons for you at this time. <br /> Be sure to check again soon.</div>
                            : 
                                <>
                                    {isTab ? 
                                        <Slider {...settings}>
                                        {
                                            salons?.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <SearchResultCard item={item}/>
                                                </div>
                                            ))
                                        }
                                        </Slider> 
                                    :
                                        <>
                                            <div className="flex">
                                                <div className="results">
                                                {
                                                    salons?.map((item, index) => (
                                                        <div className="item" key={index}>
                                                            <SearchResultCard item={item}/>
                                                        </div>
                                                    ))
                                                }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </>
                        }
                    </div>
                    {!checked ?
                        ''
                    :
                        <div className="google-map hide-on-mobile">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13610.482009735315!2d74.3000763!3d31.479624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1645796406701!5m2!1sen!2s" allowFullScreen="" loading="lazy"></iframe>
                        </div>    
                    }
                </section>
                <div className='show-on-mobile'>
                    <div className={`overlay ${dropdown ? "active" : ""}`}></div>
                </div>
            </div>
        </>
    );
}

export default Search;
